import { Component, Input, DoCheck, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService } from '../../services/config.service';
import { LoadingService } from '../../services/loading.service';
import { MediaService } from '../../services/media.service';
import { EasiHttpParams } from '../../interceptors/easi-http-params';

import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

/**
 * The workspace at the center-ish of the screen containing the H5P plugin.
 */
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements DoCheck, OnInit, OnDestroy {
    @Input() currentActivity: any;

    currentActivityUrl: SafeResourceUrl = '';
    lastActivity = '';

    refreshCurrentMedia$: Subscription;
    getMediaEdit$: Subscription;
    toggleQuizPlayerMode$: Subscription;

    /**
     * @param { DomSanitizer } domSanitizer Angular's DOM Sanitizer
     * @param { ConfigService } configService The config service
     */
    constructor(
        private domSanitizer: DomSanitizer,
        private configService: ConfigService,
        private mediaService: MediaService,
        private loadingService: LoadingService,
        private http: HttpClient
    ) {}

    ngOnInit() {
        this.refreshCurrentMedia$ = this.mediaService.refreshCurrentMedia.subscribe((data: any) => {
            if (this.currentActivityUrl) {
                this.currentActivityUrl = '';

                const config: any = {
                    withCredentials: true,
                    responseType: 'text',
                    params: new EasiHttpParams({
                        noError: true
                    })
                };

                this.getMediaEdit$ = this.http
                    .get(`/media/edit/${this.currentActivity.id}?check_error=true`, config)
                    .subscribe(
                        (success: any) => {},
                        (error: any) => {
                            if (JSON.parse(error.error).errorCode === 'ACTIVITY_NOT_FOUND') {
                                this.currentActivityUrl = '';
                            } else if (
                                JSON.parse(error.error).errorCode === 'ACTIVITY_NOT_EDITABLE'
                            ) {
                                this.currentActivityUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                                    `${this.configService.getEndPoint()}/media/play/${
                                        this.currentActivity.id
                                    }`
                                );
                            } else {
                                this.currentActivityUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                                    `${this.configService.getEndPoint()}/media/edit/${
                                        this.currentActivity.id
                                    }`
                                );
                            }
                        }
                    );
            }
        });
        this.toggleQuizPlayerMode$ = this.mediaService.quizPlayerMode.subscribe(
            (quizPlayerMode) => {
                const partialUrl = quizPlayerMode ? 'play' : 'edit';
                this.currentActivityUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `${this.configService.getEndPoint()}/media/${partialUrl}/${
                        this.currentActivity.id
                    }`
                );
            }
        );
    }

    ngOnDestroy() {
        if (this.refreshCurrentMedia$) {
            this.refreshCurrentMedia$.unsubscribe();
        }
        if (this.getMediaEdit$) {
            this.getMediaEdit$.unsubscribe();
        }
    }

    /**
     * Lifecycle hook overridden to update the iframe only when its URL changes. Otherwise Angular updates it every time the context changes.
     * Uses SafeRessourceUrls to avoid errors (and security breaches).
     */
    ngDoCheck() {
        if (this.currentActivity) {
            if (this.currentActivity.loading === true) {
                this.currentActivityUrl = '';
            } else if (this.currentActivity.loading === false) {
                this.loadingService.stopLoading();
            } else if (this.currentActivity !== '') {
                if (this.currentActivity !== this.lastActivity) {
                    this.lastActivity = this.currentActivity;
                    if (
                        this.currentActivity.status === 'approved' ||
                        this.currentActivity.status === 'waiting' ||
                        this.currentActivity.status === 'waitingForApproval'
                    ) {
                        this.currentActivityUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `${this.configService.getEndPoint()}/media/play/${
                                this.currentActivity.id
                            }`
                        );
                    } else {
                        this.currentActivityUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `${this.configService.getEndPoint()}/media/edit/${
                                this.currentActivity.id
                            }`
                        );
                    }
                    this.loadingService.startLoading();
                }
            }
        }
    }

    /**
     * @returns {boolean} true if no media is selected and we don't want to show the loading animation, false otherwise
     */
    showEmpty(): boolean {
        return this.currentActivityUrl === '';
    }
}
