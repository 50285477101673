import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Template } from '../../structures/template';

@Component({
    selector: 'app-create-media',
    templateUrl: './create-media.component.html',
    styleUrls: ['./create-media.component.scss']
})
export class CreateMediaComponent implements OnInit {
    constructor(
        public dialogNewRef: MatDialogRef<CreateMediaComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    mediaName: any;
    creationDone: Boolean;
    headerLabel: string;
    default: Template;
    key: string;

    templateList: Array<Template>;

    ngOnInit() {
        this.default = this.data.templates.filter((item) => !item.example)[0];
        this.templateList = this.data.templates.filter((item) => item.example);
        this.creationDone = false;
        this.headerLabel = `Nouveau média : ${this.data.type}`;
        this.key = this.data.key;
    }

    /**
     * Ferme la fenêtre de création de media
     */
    closeDialog() {
        this.dialogNewRef.close();
    }
}
