import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { log } from './decorators/log.decorator';

/**
 * Service for every H5P requests that can be done.
 */
@Directive()
@Injectable({
    providedIn: 'root'
})
export class TemplateService {
    @Output() openError: EventEmitter<string> = new EventEmitter();

    /**
     * @param {ConfigService} configService Config service
     * @param {HttpClient} http Angular's HTTP Client
     * @param {DomSanitizer} domSanitizer Angular's DOM Sanitizer
     * @param {LoginService} loginService Login service
     */
    constructor(private http: HttpClient) {}

    /**
     * Function used to request all the templates.
     * @returns {Observable<Object>}
     */
    @log() getAllTemplates(): Observable<object> {
        return this.http.get('/templates');
    }
}
