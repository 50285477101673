import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MediaService } from '../../services/media.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-rename-media',
    templateUrl: './rename-media.component.html',
    styleUrls: ['./rename-media.component.scss']
})
export class RenameMediaComponent implements OnInit, OnDestroy {
    constructor(
        public dialogNewRef: MatDialogRef<RenameMediaComponent>,
        private mediaService: MediaService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    updateMedia$: Subscription;

    media: any;
    renameDone: Boolean;
    mediaType: string;

    ngOnInit() {
        this.media = {
            ...this.data
        };
        this.renameDone = false;
    }

    ngOnDestroy() {
        if (this.updateMedia$) {
            this.updateMedia$.unsubscribe();
        }
    }

    /**
     * Ferme la fenêtre de création de media
     */
    closeDialog() {
        this.dialogNewRef.close();
    }

    /**
     * Crée/Met à jour le media
     */
    renameMedia() {
        if (!this.renameDone) {
            this.renameDone = true;
            this.updateMedia$ = this.mediaService
                .updateMedia(this.media.id, this.media)
                .subscribe((data: any) => {
                    this.mediaService.emitRenameMedia(this.media);
                    this.closeDialog();
                });
        }
    }
}
