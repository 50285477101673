<div class="header">
    <span class="icon icon-{{ key }}-circle"></span>
    <span class="label">{{ headerLabel }}</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <mat-form-field>
        <div class="name">
            <mat-label class="label">Nom</mat-label>
            <input
                matInput
                class="value"
                type="text"
                placeholder="Nom du media"
                [(ngModel)]="mediaName"
            />
        </div>
        <app-media-template
            [default]="default"
            [mediaName]="mediaName"
            [dialogRef]="dialogNewRef"
        ></app-media-template>
        <p
            style="margin-left: 4%; margin-right: 4%; font-style: italic"
            *ngIf="templateList.length > 0"
        >
            Si vous ne connaissez pas encore très bien ce type d'activité et les possibilités
            qu'elle offre, vous pouvez travailler en copiant l'un des modèles ci-dessous, que vous
            pourrez facilement modifier pour l'adapter à vos contenus.
        </p>
        <div *ngFor="let template of templateList">
            <app-media-template
                [template]="template"
                [mediaName]="mediaName"
                [dialogRef]="dialogNewRef"
            ></app-media-template>
        </div>
    </mat-form-field>
</mat-dialog-content>
