<div class="main" [ngClass]="{ hidden: reduced }">
    <div class="header">
        <div class="medias-count">
            <span class="icon-local"></span>
            <span class="label"
                >Mes médias <b>({{ totalMediasCount }})</b></span
            >
        </div>
        <span
            class="icon-minimizebloc"
            [ngClass]="{ inversed: reduced }"
            (click)="toggleReduce()"
        ></span>
    </div>
    <div class="separator"></div>
    <div class="container">
        <div class="pending-medias">
            <div class="title" (click)="togglePending()">
                <span class="icon-assemblage"></span>
                <span class="label"
                    >Mes médias en cours de création ({{ mediasList.pending.length }})</span
                >
                <span
                    class="icon-down"
                    *ngIf="mediasList.pending.length > 0"
                    [ngClass]="{ open: !isClosedPending }"
                ></span>
            </div>
            <div id="pending-medias-container" [@slideToggle]="isClosedPending ? 'closed' : 'open'">
                <app-nav-entry
                    *ngFor="let media of mediasList.pending"
                    [media]="media"
                    (click)="selectMedia(media)"
                    [selectedMedia]="selectedMedia?.id"
                    [type]="'pending'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second"></div>
        <div class="draft-medias">
            <div class="title" (click)="toggleDraft()">
                <span class="icon-Attente"></span>
                <span class="label"
                    >Mes médias en attente de publication dans training ({{
                        mediasList.draft.length
                    }})</span
                >
                <span
                    class="icon-down"
                    *ngIf="mediasList.draft.length > 0"
                    [ngClass]="{ open: !isClosedDraft }"
                ></span>
            </div>
            <div id="draft-medias-container" [@slideToggle]="isClosedDraft ? 'closed' : 'open'">
                <app-nav-entry
                    *ngFor="let media of mediasList.draft"
                    [media]="media"
                    (click)="selectMedia(media)"
                    [selectedMedia]="selectedMedia?.id"
                    [type]="'author'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second"></div>
        <div class="waiting-medias" [ngClass]="{ hidden: isValidator() }">
            <div class="title" (click)="toggleWaiting()">
                <span class="icon-valideur"></span>
                <span class="label"
                    >Mes médias en attente de validation ({{ mediasList.waiting.length }})</span
                >
                <span
                    class="icon-down"
                    *ngIf="mediasList.waiting.length > 0"
                    [ngClass]="{ open: !isClosedWaiting }"
                ></span>
            </div>
            <div id="waiting-medias-container" [@slideToggle]="isClosedWaiting ? 'closed' : 'open'">
                <app-nav-entry
                    *ngFor="let media of mediasList.waiting"
                    [media]="media"
                    (click)="selectMedia(media)"
                    [selectedMedia]="selectedMedia?.id"
                    [type]="'waiting'"
                ></app-nav-entry>
            </div>
        </div>
        <div class="separator second"></div>
        <div class="approved-medias">
            <div class="title" (click)="toggleApproved()">
                <span class="icon-Valider"></span>
                <span class="label"
                    >Mes médias validés et publiés ({{ mediasList.approved.length }})</span
                >
                <span
                    class="icon-down"
                    *ngIf="mediasList.approved.length > 0"
                    [ngClass]="{ open: !isClosedApproved }"
                ></span>
            </div>
            <div
                id="approved-medias-container"
                [@slideToggle]="isClosedApproved ? 'closed' : 'open'"
            >
                <app-nav-entry
                    *ngFor="let media of mediasList.approved"
                    [media]="media"
                    (click)="selectMedia(media)"
                    [selectedMedia]="selectedMedia?.id"
                    [type]="'approved'"
                ></app-nav-entry>
            </div>
        </div>
    </div>
    <div class="content-selection" *ngIf="isMediaTypeSelectorClosed()">
        <div
            class="template-entry"
            *ngFor="let template of templates"
            [ngClass]="{ disabled: template.disabled }"
            (click)="openDialogNewMedia(template)"
            [tooltip]="template.disabled ? template.tooltip : null"
        >
            <span class="icon" [ngClass]="'icon-' + template.type"></span>
            <div class="label">{{ template.name }}</div>
        </div>
    </div>

    <div class="separator"></div>
    <div class="add-media" (click)="toggleMediaTypeSelector()">
        <span class="icon-plus"></span>
    </div>
</div>
