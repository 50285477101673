<div class="container">
    <div class="template">
        <div class="row">
            <div class="col-3" *ngIf="!default">
                <img [src]="url" [alt]="title" />
            </div>
            <div class="col">
                <div class="container">
                    <div class="row">
                        <div class="col" id="title" [class.col-3]="default">
                            <span>{{ title }}</span>
                        </div>
                        <div class="col icon-container" *ngIf="!default">
                            <span
                                class="icon-see preview"
                                tooltip="Tester"
                                (click)="openPreview()"
                            ></span>
                        </div>
                        <div id="defaultButtonContainer" class="col" *ngIf="default">
                            <mat-dialog-actions>
                                <button
                                    id="createBlank"
                                    class="button-create"
                                    *ngIf="mediaName !== ''"
                                    mat-button
                                    (click)="createMedia()"
                                >
                                    OUVRIR L'ÉDITEUR POUR TRAVAILLER À PARTIR D'UN MODÈLE VIDE
                                </button>
                                <button
                                    id="createBlank"
                                    class="button-create disabled"
                                    *ngIf="mediaName === ''"
                                >
                                    VEUILLEZ ENTRER UN NOM
                                </button>
                            </mat-dialog-actions>
                        </div>
                    </div>
                    <div class="row" *ngIf="!default">
                        <div class="col-12 align-self-start">
                            {{ description }}
                        </div>
                        <div class="col-12 align-self-end">
                            <mat-dialog-actions>
                                <button
                                    class="button-create"
                                    *ngIf="mediaName !== ''"
                                    mat-button
                                    (click)="createMedia()"
                                >
                                    CRÉER À PARTIR DE CE MODÈLE
                                </button>
                                <button
                                    class="button-create disabled"
                                    *ngIf="mediaName === ''"
                                    (click)="createMedia()"
                                >
                                    VEUILLEZ ENTRER UN NOM
                                </button>
                            </mat-dialog-actions>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
