import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Media } from '../../structures/media';
import { Template } from '../../structures/template';

import { DialogService } from '../../services/dialog.service';
import { LoginService } from '../../services/login.service';
import { MediaService } from 'src/app/services/media.service';
import { TemplateService } from 'src/app/services/template.service';
import { Subscription } from 'rxjs';
import { slideToggle } from 'src/app/animations/slide-toggle.animation';

/**
 * Composant gérant l'affichage du menu de gauche
 */
@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    animations: [slideToggle]
})
export class NavComponent implements OnInit, OnDestroy {
    /**
     * @param {EventEmitter<any>} select emet un event pour signaler le media sélectionné
     */
    @Output() resize: EventEmitter<boolean> = new EventEmitter();
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    openSaveConfirmationDialog$: Subscription;
    getMedias$: Subscription;
    refreshAllMedias$: Subscription;
    openActivity$: Subscription;
    clonedMedia$: Subscription;
    getAllTemplates$: Subscription;

    totalMediasCount: number;

    mediasList = {
        draft: [],
        pending: [],
        waiting: [],
        approved: []
    };

    templates: Template[] = [];

    isClosedDraft: boolean;
    isClosedPending: boolean;
    isClosedWaiting: boolean;
    isClosedApproved: boolean;

    root: any;
    bin: any;

    selectedMedia: any;

    reduced = false;

    private showMediaTypeSelector = false;

    constructor(
        private dialogService: DialogService,
        private loginService: LoginService,
        private mediaService: MediaService,
        private templateService: TemplateService
    ) {}

    /**
     * Sélectionne un media à afficher dans la vue principale
     */
    selectMedia(media: any) {
        if (
            this.selectedMedia &&
            this.selectedMedia.id !== media.id &&
            this.dialogService.getDisplaySaveConfirmation()
        ) {
            this.openSaveConfirmationDialog$ = this.dialogService
                .openSaveConfirmationDialog()
                .subscribe((data: boolean) => {
                    if (data) {
                        this.selectedMedia = media;
                        this.select.emit(media);
                        this.toggleReduce();
                    }
                });
        } else {
            this.selectedMedia = media;
            this.select.emit(media);
            this.toggleReduce();
        }
    }

    /**
     * Rafraichissement de la liste des médias de l'utilisateur courant
     */
    refreshMediaList() {
        this.getMedias$ = this.mediaService.getMedias().subscribe((medias: any) => {
            this.totalMediasCount = medias.length;
            this.mediasList = this.parseMediasByStatus(medias);
        });
    }

    isValidator() {
        if (this.loginService.getUser()) {
            if (
                this.loginService.getUser().roles.nationalAdmin ||
                this.loginService.getUser().roles.localAdmin ||
                this.loginService.getUser().roles.validator
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     * Parse la liste des médias et dispatche ceux-ci en fonction de leur statut
     * @param medias Le tableau contenant les objets media de l'utilisatuer
     * @returns parsedMedia
     */
    parseMediasByStatus(
        medias: Array<any>
    ): {
        draft: Media[];
        pending: Media[];
        waiting: Media[];
        approved: Media[];
    } {
        const draft: Media[] = medias.filter((item) => item.status === 'draft');
        const pending: Media[] = medias.filter((item) => item.status === 'pending');
        const waiting: Media[] = medias.filter((item) => item.status === 'waitingForApproval');
        const approved: Media[] = medias.filter((item) => item.status === 'approved');

        return {
            draft,
            pending,
            waiting,
            approved
        };
    }

    toggleReduce() {
        this.showMediaTypeSelector = false;
        this.reduced = !this.reduced;
        this.resize.emit(this.reduced);
    }

    /**
     * Déplie/replie la liste des médias dont le statut est pending
     */
    togglePending() {
        this.isClosedPending = !this.isClosedPending;
        if (!this.isClosedPending && !this.isClosedDraft) {
            this.toggleDraft();
        }
        if (!this.isClosedPending && !this.isClosedWaiting) {
            this.toggleWaiting();
        }
        if (!this.isClosedPending && !this.isClosedApproved) {
            this.toggleApproved();
        }
    }

    /**
     * Déplie/replie la liste des médias dont le statut est pending
     */
    toggleDraft() {
        this.isClosedDraft = !this.isClosedDraft;
        if (!this.isClosedDraft && !this.isClosedPending) {
            this.togglePending();
        }
        if (!this.isClosedDraft && !this.isClosedWaiting) {
            this.toggleWaiting();
        }
        if (!this.isClosedDraft && !this.isClosedApproved) {
            this.toggleApproved();
        }
    }

    /**
     * Déplie/replie la liste des médias dont le statut est waiting
     */
    toggleWaiting() {
        this.isClosedWaiting = !this.isClosedWaiting;
        if (!this.isClosedWaiting && !this.isClosedPending) {
            this.togglePending();
        }
        if (!this.isClosedWaiting && !this.isClosedDraft) {
            this.toggleDraft();
        }
        if (!this.isClosedWaiting && !this.isClosedApproved) {
            this.toggleApproved();
        }
    }

    /**
     * Déplie/replie la liste des médias dont le statut est approved
     */
    toggleApproved() {
        this.isClosedApproved = !this.isClosedApproved;
        if (!this.isClosedApproved && !this.isClosedPending) {
            this.togglePending();
        }
        if (!this.isClosedApproved && !this.isClosedDraft) {
            this.toggleDraft();
        }
        if (!this.isClosedApproved && !this.isClosedWaiting) {
            this.toggleWaiting();
        }
    }

    /**
     * Hide or show the tooltip above the plus button used to select a new content type by switching the value of showMediaTypeSelector.
     */
    selectNewContentType() {
        this.showMediaTypeSelector = !this.showMediaTypeSelector;
    }
    /**
     * @returns {boolean} The value of showMediaSelector
     */
    isMediaTypeSelectorClosed(): boolean {
        return this.showMediaTypeSelector;
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Ouvre la fenêtre de création de média
     */
    toggleMediaTypeSelector() {
        this.showMediaTypeSelector = !this.showMediaTypeSelector;
    }

    /**
     * @param {Template} template le template associé au bouton cliqué
     * Ouvre le dialogue de création de média
     */
    openDialogNewMedia(template: Template) {
        if (!template.disabled) {
            this.dialogService.openCreateMediaDialog(template);
            this.showMediaTypeSelector = false;
        }
    }

    ngOnInit() {
        this.refreshMediaList();
        this.isClosedPending = false;
        this.isClosedDraft = false;
        this.isClosedWaiting = false;
        this.isClosedApproved = false;

        this.toggleDraft();
        this.toggleWaiting();
        this.toggleApproved();

        this.refreshAllMedias$ = this.mediaService.refreshAllMedias.subscribe(() => {
            this.refreshMediaList();
        });
        this.openActivity$ = this.mediaService.openActivity.subscribe((media) => {
            this.selectMedia(media);
        });
        this.clonedMedia$ = this.mediaService.clonedMedia.subscribe((media: Media) => {
            this.mediasList.pending.push(media);
            this.selectMedia(media);
            if (this.isClosedPending) {
                this.togglePending();
            }
        });

        this.getAllTemplates$ = this.templateService.getAllTemplates().subscribe((data: any) => {
            this.templates = data.map((template) => ({
                ...template,
                disabled: template.type !== 'quiztrain',
                tooltip:
                    template.type === 'machine'
                        ? `Dans le cadre de l'évolution d'EASi, EASi Machine disparaît. Votre référent EASi pourra vous proposer une alternative plus pérenne.`
                        : `Dans le cadre de l'évolution d'EASi, ce média est temporairement indisponible, il sera bientôt réactivé.`
            }));
            console.log(this.templates);
        });
    }

    ngOnDestroy() {
        if (this.openSaveConfirmationDialog$) {
            this.openSaveConfirmationDialog$.unsubscribe();
        }
        if (this.getMedias$) {
            this.getMedias$.unsubscribe();
        }
        if (this.refreshAllMedias$) {
            this.refreshAllMedias$.unsubscribe();
        }
        if (this.openActivity$) {
            this.openActivity$.unsubscribe();
        }
        if (this.clonedMedia$) {
            this.clonedMedia$.unsubscribe();
        }
        if (this.getAllTemplates$) {
            this.getAllTemplates$.unsubscribe();
        }
    }
}
