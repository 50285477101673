<div class="container" [ngClass]="[getBackgroundStyle(), isSelected()]">
    <div [ngClass]="'icon-' + media.type"></div>
    <div class="title">{{ media.name }}</div>

    <span
        class="icon-easi-training-line published"
        *ngIf="isPublished()"
        tooltip="Disponible dans l'espace de création de contenus d'easi training"
    ></span>
    <span
        class="icon-easi-training-line published"
        *ngIf="isApproved()"
        tooltip="Publié dans la bibliothèque easi training"
    ></span>
    <div class="icons">
        <span
            class="icon-Editer"
            *ngIf="(!isPublished() && !isApproved()) || media.status === 'draft'"
            tooltip="Renommer"
            (click)="openRenameMediaDialog($event)"
        ></span>
        <span
            class="icon-bin"
            *ngIf="!isPublished() && !isApproved()"
            tooltip="Supprimer"
            (click)="openDeleteMediaDialog($event)"
        ></span>
        <span class="icon-Cloner" tooltip="Cloner" (click)="cloneMedia($event)"></span>
        <span
            class="icon-easi-training-line"
            *ngIf="!isPublished() && !isApproved()"
            tooltip="Envoyer dans l'espace de création de contenus d'easi training"
            (click)="publishMedia()"
        ></span>
    </div>
</div>
