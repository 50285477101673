import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ApplicationRef } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavComponent } from './components/nav/nav.component';
import { NavEntryComponent } from './components/nav/nav-entry/nav-entry.component';
import { FlashMessageComponent } from './components/flash-message/flash-message.component';
import { LoadingComponent } from './components/loading/loading.component';

import { CarouselComponent } from './components/carousel/carousel.component';

import { WarningComponent } from './dialogs/warning/warning.component';
import { ErrorComponent } from './dialogs/error/error.component';
import { SaveMediaConfirmationComponent } from './dialogs/save-media-confirmation/save-media-confirmation.component';
import { CreateMediaComponent } from './dialogs/create-media/create-media.component';
import { RenameMediaComponent } from './dialogs/rename-media/rename-media.component';
import { MediaTemplateComponent } from './dialogs/create-media/media-template/media-template.component';

import { ConfigService } from './services/config.service';
import { LoginService } from './services/login.service';
import { DialogService } from './services/dialog.service';

import { isIE } from 'mobile-device-detect';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { LogInterceptor } from './interceptors/log.interceptor';
import { EndpointInterceptor } from './interceptors/endpoint.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { TooltipModule } from 'uimm-tooltip';

import { CookieService } from 'ngx-cookie-service';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        FooterComponent,
        NavComponent,
        NavEntryComponent,
        WarningComponent,
        ErrorComponent,
        SaveMediaConfirmationComponent,
        CreateMediaComponent,
        RenameMediaComponent,
        MediaTemplateComponent,
        CarouselComponent,
        FlashMessageComponent,
        LoadingComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        TooltipModule,
        LoadingBarModule,
        LoadingBarHttpClientModule
    ],
    providers: [
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private configService: ConfigService,
        private loginService: LoginService,
        private dialogService: DialogService
    ) {}

    ngDoBootstrap(app) {
        if (isIE) {
            window.location.href = 'https://cdn.easi-training.fr/assets/html/ie.html';
        }
        const getConfig = new XMLHttpRequest();
        getConfig.onload = (data: any) => {
            const response = JSON.parse(data.target.response);
            this.configService.setConfig(response);
            this.loginService.postCookies().subscribe((dataFromPost: number) => {
                this.loginService.getCookies().subscribe((dataFromGet: number) => {
                    if (dataFromPost === dataFromGet) {
                        if (response.profile) {
                            this.getProfileConfigSetConfigAndBootstrap(response, app);
                        } else {
                            this.setConfigAndBootStrap(response, app);
                        }
                    } else {
                        this.dialogService.openErrorDialog(
                            'Veuillez autoriser les cookies pour poursuivre votre navigation sur easi'
                        );
                    }
                });
            });
        };
        getConfig.open('get', `./assets/config/config.json?${Date.now()}`, true);
        getConfig.send();
    }

    private setConfigAndBootStrap(config: object, app: ApplicationRef): void {
        this.configService.setConfig(config);
        this.loginService.getCurrentUserMedia().subscribe(() => {
            app.bootstrap(AppComponent);
        });
    }

    private getProfileConfigSetConfigAndBootstrap(
        response: { profile: string },
        app: ApplicationRef
    ): void {
        const getProfileConfig = new XMLHttpRequest();

        getProfileConfig.onload = (data2: any) => {
            const profileResponse = JSON.parse(data2.target.response);
            this.setConfigAndBootStrap(
                {
                    ...response,
                    ...profileResponse
                },
                app
            );
        };
        getProfileConfig.open('get', `./assets/config/${response.profile}.json?${Date.now()}`);
        getProfileConfig.send();
    }
}
