import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';

import { WarningComponent } from '../dialogs/warning/warning.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { CreateMediaComponent } from '../dialogs/create-media/create-media.component';
import { RenameMediaComponent } from '../dialogs/rename-media/rename-media.component';
import { SaveMediaConfirmationComponent } from '../dialogs/save-media-confirmation/save-media-confirmation.component';

import { Template } from '../structures/template';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private dialog: MatDialog,
        private cookieService: CookieService,
        private loginService: LoginService
    ) {
        this.subscriptions.add(
            this.loginService.closeAllDialogs$.subscribe((data: boolean) => {
                if (data) {
                    this.dialog.closeAll();
                }
            })
        );
    }

    private subscriptions: Subscription = new Subscription();

    /**
     * @param template Le template à utiliser pour le nouveau média
     * Ouvre la fenetre de création de media
     */
    openCreateMediaDialog(template: Template): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {
            ...template,
            genericType: template.genericType,
            type: template.name,
            mediaId: undefined
        };

        const ref: MatDialogRef<CreateMediaComponent> = this.dialog.open(
            CreateMediaComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param { string } warning L'action que l'on souhaite effectuer une fois la confirmation de l'utilisateur obtenu
     * @param { number } warningId L'id de l'élement sur lequel on souhaite agir
     * Ouvre une fenetre d'avertissement/confirmation avant d'effectuer une action
     */
    openWarningDialog(warning: string, warningId?: number) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = {};
        dialogConfig.data.warning = warning;
        if (warningId) {
            dialogConfig.data.warningId = warningId;
        }

        const ref: MatDialogRef<WarningComponent> = this.dialog.open(
            WarningComponent,
            dialogConfig
        );
        ref.componentInstance.openErrorDialog.subscribe((msg: string) => {
            this.openErrorDialog(msg);
        });
    }

    /**
     * @param {string} content Le message d'erreur que l'on souhaite afficher
     * Ouvre la fenetre d'erreur
     */
    openErrorDialog(content?: String) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';
        if (content) {
            dialogConfig.data = content;
        }

        this.dialog.open(ErrorComponent, dialogConfig);
    }

    openRenameMediaDialog(media) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        dialogConfig.data = media;

        this.dialog
            .open(RenameMediaComponent, dialogConfig)
            .componentInstance.openErrorDialog.subscribe((msg: string) => {
                this.openErrorDialog(msg);
            });
    }

    openSaveConfirmationDialog(): Observable<undefined> {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '600px';
        dialogConfig.maxHeight = '95vh';

        const ref = this.dialog.open(SaveMediaConfirmationComponent, dialogConfig);
        return ref.afterClosed();
    }

    getDisplaySaveConfirmation(): boolean {
        return !this.cookieService.check('display save confirmation');
    }
}
