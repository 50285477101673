import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, PartialObserver, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoadingService } from '../services/loading.service';

import { Media } from '../structures/media';
import { FlashMessageService } from './flash-message.service';
import { log } from './decorators/log.decorator';
import { ConfigService } from './config.service';

/**
 * Service gérant tous les appels aux webservice liés aux media
 */
@Directive()
@Injectable({
    providedIn: 'root'
})
export class MediaService {
    constructor(
        private http: HttpClient,
        private loadingService: LoadingService,
        private flashMessageService: FlashMessageService,
        private configService: ConfigService
    ) {}

    quizPlayerMode: Subject<boolean> = new Subject();

    @Output() refreshAllMedias: EventEmitter<any> = new EventEmitter();
    @Output() refreshCurrentMedia: EventEmitter<any> = new EventEmitter();
    @Output() openActivity: EventEmitter<any> = new EventEmitter();
    @Output() pushNewMedia: EventEmitter<Media> = new EventEmitter<Media>();
    @Output() renamedMedia: EventEmitter<Media> = new EventEmitter<Media>();
    @Output() clonedMedia: EventEmitter<Media> = new EventEmitter<Media>();

    /**
     * Récupère la liste des medias accessibles par l'utilisateur actuel
     * @returns {Observable} Un observable de la liste des medias de l'utilisateur courant
     */
    @log() getMedias(): Observable<any> {
        return this.http.get('/activities');
    }

    /**
     * Request to create a new media. /!\ The actual subscription must be done by the caller /!\
     * @param {MediaDescription} media Content of the media to create
     * @returns {Observable<Object>} An observable.
     */
    @log() createMedia(body: Media): Observable<any> {
        this.flashMessageService.flash(
            'Votre activité est en cours de création, merci de patienter jusqu’à ce qu’elle soit disponible...'
        );
        this.loadingService.startLoading();

        return this.http.post('/activities', body);
    }

    /**
     * @param {number} mediaId L'identifiant du media que l'on souhaite mettre à jour
     * @param {any} body Un object contenant 1 paramètre : Un objet représentant un media existant
     * Met à jour un media
     * @returns {Observable} Un observable d'un booléen si la mise à jour du media s'est déroulé correctement
     */
    @log() updateMedia(mediaId: number, body: any): Observable<any> {
        return this.http.put('/activities/' + mediaId, body).pipe(
            tap({
                next: (data) => {
                    this.flashMessageService.flash(`Le média a été mis à jour.`);
                }
            })
        );
    }

    /**
     * @param {number} id L'identifiant du media à récuperer
     * Supprime un media
     * @returns {Observable} Un observable d'un booléen indiquant si la suppression s'est déroulé correctement
     */
    @log() deleteMedia(id: number): Observable<any> {
        return this.http.delete('/activities/' + id);
    }

    /**
     * Clones a media
     * @param {Media} media the media to be cloned
     */
    @log() cloneMedia(media: Media): Observable<any> {
        this.flashMessageService.flash(
            'Votre activité est en cours de création, merci de patienter jusqu’à ce qu’elle soit disponible...'
        );

        return this.http.post('/media/clone/' + media.id, null);
    }

    toggleQuizPlayerMode(quizPlayerMode: boolean) {
        this.quizPlayerMode.next(quizPlayerMode);
        const flashMessage = quizPlayerMode
            ? 'Chargement du quiz, merci de patienter. '
            : "Chargement de l'éditeur, merci de patienter.";
        this.flashMessageService.flash(flashMessage);
    }

    /**
     * Publish an activity
     * @param id id of the activity
     */
    publishMedia(id: number) {
        window.open(this.configService.getEndPoint() + '/media/' + id + '/publish');
    }

    /**
     * Un event récupérable par les composants permettant de mettre à jour la liste des medias
     */
    refreshMedias() {
        this.refreshAllMedias.emit('');
    }

    refreshMedia() {
        this.refreshCurrentMedia.emit('');
    }

    emitRenameMedia(media: Media) {
        this.renamedMedia.emit(media);
    }

    emitCloneMedia(media: Media) {
        this.clonedMedia.emit(media);
    }
}
