<div class="header">
    <span class="icon"></span>
    <span class="label">Modifier le nom</span>
    <span class="icon-close" (click)="closeDialog()"></span>
</div>
<mat-dialog-content class="content">
    <mat-form-field>
        <div class="name">
            <mat-label class="label">Nom</mat-label>
            <input
                matInput
                class="value"
                type="text"
                placeholder="Nom du media"
                [(ngModel)]="media.name"
            />
        </div>
    </mat-form-field>
    <div class="separator"></div>
</mat-dialog-content>
<div class="footer">
    <div
        class="rename-button"
        [ngClass]="{ disabled: false }"
        (click)="renameMedia()"
        *ngIf="!renameDone"
    >
        Renommer
    </div>
    <div class="rename-button" *ngIf="renameDone">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </div>
</div>
