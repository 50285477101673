import { HttpParams } from '@angular/common/http';

/**
 * Classe permettant de faire passer une string custom avec une requête http, qui sert aux intercepteurs
 * params correspond aux paramètres initiaux de la requête.
 */
export class EasiHttpParams extends HttpParams {
    constructor(args: {
        log?: string;
        params?: { [param: string]: any | any[] };
        noError?: boolean;
        noCredentials?: boolean;
        overrideBaseUrl?: boolean;
    }) {
        super({ fromObject: args.params });
        if (args.log) {
            this.log = args.log;
        }
        if (args.noError === true) {
            this.noError = args.noError;
        }
        if (args.noCredentials) {
            this.noCredentials = args.noCredentials;
        }
        if (args.overrideBaseUrl) {
            this.overrideBaseUrl = args.overrideBaseUrl;
        }
    }
    log: string;
    noError: boolean = false;
    noCredentials: boolean = false;
    overrideBaseUrl: boolean = false;
}
