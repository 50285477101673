import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../services/config.service';
import { MediaService } from '../../../services/media.service';
import { LoadingService } from '../../../services/loading.service';

import { CreateMediaComponent } from '../create-media.component';

import { Media } from 'src/app/structures/media';

@Component({
    selector: 'app-media-template',
    templateUrl: './media-template.component.html',
    styleUrls: ['./media-template.component.scss']
})
export class MediaTemplateComponent implements OnInit, OnDestroy {
    @Input() template;
    @Input() default;
    @Input() mediaName: string;
    @Input() dialogRef: MatDialogRef<CreateMediaComponent>;

    title: string;
    description: string;
    author: string;
    type: string;

    media: Media;

    createMedia$: Subscription;

    private previewUrl: string;
    private _url: string;
    private tooltipVisibility = false;

    /**
     * @param {DomSanitizer} sanitizer Angular's DOM Sanitizer
     */
    constructor(
        private sanitizer: DomSanitizer,
        private configService: ConfigService,
        private mediaService: MediaService,
        private loadingService: LoadingService
    ) {}

    /**
     * @ignore
     */
    ngOnInit() {
        this.mediaName = '';
        if (this.template) {
            this.title = this.template.name;
            this.description = this.template.description;
            this.type = this.template.key;
            this.previewUrl =
                this.configService.getEndPoint() + '/media/preview_template/' + this.template.key;
            this._url =
                'https://cdn.easi-training.fr/assets/img/media/templates/' +
                this.template.key +
                '.png';
        } else if (this.default) {
            this.type = this.default.key;
        }
    }

    ngOnDestroy() {
        if (this.createMedia$) {
            this.createMedia$.unsubscribe();
        }
    }

    /**
     * @returns {boolean} tooltipVisibility
     */
    isTooltipVisible(): boolean {
        return this.tooltipVisibility;
    }

    /**
     * Show the tooltip
     */
    showTooltip() {
        this.tooltipVisibility = true;
    }

    /**
     * Hide the tooltip
     */
    hideTooltip() {
        this.tooltipVisibility = false;
    }

    openPreview() {
        window.open(this.previewUrl, 'blank');
    }

    /**
     * Getter to have a safe URL for the H5P template
     * @returns {SafeUrl}
     */
    get url() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this._url);
    }

    /**
     * Crée/Met à jour le media
     */
    createMedia() {
        this.media = {
            name: this.mediaName,
            key: this.type
        };
        this.createMedia$ = this.mediaService.createMedia(this.media).subscribe((data: any) => {
            this.media.id = data.id;
            this.closeDialog();
            this.loadingService.stopLoading();
            this.mediaService.refreshMedias();
            this.mediaService.openActivity.emit(this.media);
        });
    }

    /**
     * Ferme la fenêtre de création de media (composant parent)
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
