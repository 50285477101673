import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MediaService } from '../app/services/media.service';
import { LoginService } from '../app/services/login.service';
import { ConfigService } from '../app/services/config.service';
import { LoadingService } from '../app/services/loading.service';
import { DialogService } from '../app/services/dialog.service';
import { isMobile } from 'mobile-device-detect';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    subscriptions = new Subscription();

    currentMedia: number;
    mouseMoved: boolean;
    isPanelOpen = true;
    loading = true;

    constructor(
        private mediaService: MediaService,
        private loginService: LoginService,
        private configService: ConfigService,
        private loadingService: LoadingService,
        private dialogService: DialogService
    ) {}

    @HostListener('window:message', ['$event']) onMessage(event: any) {
        if (event.data.logout) {
            window.location.href = event.data.logout;
        } else if (event.data === 'getURL') {
            this.subscriptions.add(
                this.loginService.getCurrent().subscribe((data: any) => {
                    if (data === 0) {
                        window.location.href = this.configService.getRedirectEndPoint();
                    } else {
                        if (isMobile) {
                            if (this.loading) {
                                this.dialogService.openErrorDialog(
                                    "Ce site n'est pas optimisé pour un usage mobile, préférez une navigation sur ordinateur"
                                );
                            }
                        }
                        this.loading = false;
                    }
                })
            );
        }
        if (event.data === 'refresh') {
            this.mediaService.refreshMedias();
        } else if (event.data === 'refreshCurrentMedia') {
            this.mediaService.refreshMedias();
        } else if (event.data === 'hide loading screen') {
            this.loadingService.stopLoading();
        } else if (event.data === 'refresh media content') {
            this.mediaService.refreshMedias();
            this.mediaService.refreshMedia();
        } else if (event.data.message === 'toggle quizPlayer') {
            this.mediaService.toggleQuizPlayerMode(true);
        } else if (event.data.message === 'toggle quizEditor') {
            this.mediaService.toggleQuizPlayerMode(false);
        }
    }

    @HostListener('window:mousemove', ['$event']) onMouseMoved(event: any) {
        this.mouseMoved = true;
    }

    ngOnInit() {
        this.mouseMoved = false;
        this.pingGetCurrent();
        this.loginService.initCheckConnectedInterval();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    updateCurrentMedia(event: number) {
        this.currentMedia = event;
    }

    toggleNav() {
        this.isPanelOpen = !this.isPanelOpen;
    }

    showLoading(): boolean {
        return this.loadingService.isLoading();
    }

    isInitialLoading(): boolean {
        return this.loading;
    }

    pingGetCurrent() {
        setInterval(() => {
            if (this.mouseMoved) {
                this.subscriptions.add(
                    this.loginService.getCurrentUserMedia().subscribe((data: any) => {
                        this.mouseMoved = false;
                    })
                );
            }
        }, 1000 * 20 * 60);
    }
}
