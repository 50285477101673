<div [ngClass]="{ hidden: isInitialLoading() }">
    <app-header class="easi-header"></app-header>
    <app-flash-message></app-flash-message>
    <app-nav
        class="left-panel"
        [ngClass]="{ reduced: !isPanelOpen }"
        routerLink="user"
        (select)="updateCurrentMedia($event)"
        (resize)="toggleNav()"
    ></app-nav>
    <app-main
        class="main-panel"
        [ngClass]="{ enlarged: !isPanelOpen }"
        [currentActivity]="currentMedia"
    ></app-main>
    <div class="loading-screen" *ngIf="showLoading()">
        <div class="loadingEntry">
            <div class="point1 point"></div>
            <div class="point2 point"></div>
            <div class="point3 point"></div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
<app-loading *ngIf="isInitialLoading()"></app-loading>
