import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, fromEvent, Subject } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { ConfigService } from './config.service';

import { CookieService } from 'ngx-cookie-service';

import { log } from './decorators/log.decorator';

/**
 * Service gérant l'utilisateur actuel
 */
@Injectable({
    providedIn: 'root'
})
export class LoginService {
    /**
     * L'utilisateur actuel
     */
    user: any;
    moderator: number[];
    lastStructure: any = {};
    closeAllDialogs$: Subject<boolean> = new Subject();

    @Output() updateStructure: EventEmitter<any> = new EventEmitter();

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private cookieService: CookieService
    ) {}

    /**
     * Renvoie l'utilisateur actuel
     * @returns {any} Un objet représentant l'utilisateur actuel tel que renvoyé par getCurrentUser
     */
    getUser(): any {
        return this.user;
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur
     * @returns {boolean} True si l'utilisateur actuel est un administrateur, faux dans le cas contraire
     */
    isAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin || this.user.roles.localAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur national
     * @returns {boolean} True si l'utilisateur actuel est un administrateur national, faux dans le cas contraire
     */
    isNationalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.nationalAdmin;
        }
    }

    /**
     * Indique si l'utilisateur actuel est un administrateur local
     * @returns {boolean} True si l'utilisateur actuel est un administrateur local, faux dans le cas contraire
     */
    isLocalAdmin(): boolean {
        if (this.user) {
            return this.user.roles.localAdmin;
        }
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getCurrent(): Observable<any> {
        const iframe: any = document.getElementById('header-container');
        iframe.contentWindow.postMessage('getCurrent', '*');

        return fromEvent(window, 'message').pipe(
            filter((data: any) => {
                return data.data.current !== undefined;
            }),
            tap({
                error: (error) => {
                    this.isAuthenticated(error);
                }
            })
        );
    }

    /**
     * Renvoie l'utilisateur actuellement connecté sur la plateforme
     * @returns {Observable} Un observable de l'utilisateur actuel
     */
    @log() getCurrentUserMedia(): Observable<any> {
        return this.http.get('/user/current').pipe(
            tap(
                (data) => {
                    if (this.cookieService.check('redirect-url')) {
                        const redirectUrl = this.cookieService.get('redirect-url');
                        this.cookieService.delete('redirect-url', '/');
                        window.location.href = redirectUrl;
                    }
                    this.user = data;
                },
                (error) => {
                    const expires = new Date();
                    expires.setFullYear(expires.getFullYear() + 1);
                    this.cookieService.set('redirect-url', window.location.href, expires, '/');
                }
            )
        );
    }

    @log() postCookies() {
        return this.http.post('/setTestCookie', undefined);
    }
    @log() getCookies() {
        return this.http.get('/getTestCookie');
    }

    isAuthenticated(error: HttpErrorResponse) {
        if (error.error.errorCode && error.error.errorCode === 'USER_NOT_AUTH') {
            window.location.href = this.configService.getRedirectEndPoint();
        }
    }

    initCheckConnectedInterval() {
        setInterval(() => {
            this.http.get<boolean>(`/is_still_connected.php`).subscribe((data: boolean) => {
                if (data === false) {
                    const iframe: HTMLIFrameElement = document.getElementById(
                        'header-container'
                    ) as HTMLIFrameElement;
                    iframe.contentWindow.postMessage('userDisconnected', '*');
                    this.closeAllDialogs$.next(true);
                }
            });
        }, 5 * 60 * 1000);
    }
}
