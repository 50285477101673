import { Component, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { MediaService } from '../../services/media.service';

@Component({
    selector: 'app-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<WarningComponent>,
        private mediaService: MediaService,

        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    deleteMedia$: Subscription;

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    ngOnDestroy() {
        if (this.deleteMedia$) {
            this.deleteMedia$.unsubscribe();
        }
    }

    /**
     * Détermine le message à afficher dans le bandeau
     * @returns {String} Le message à afficher dans le bandeau
     */
    getHeader(): String {
        if (this.data.warning === 'DELETE_MEDIA') {
            return "Suppression d'un media";
        }
    }

    /**
     * Détermine le message à afficher dans le corps de la fenêtre
     * @returns {String} Le message à afficher dans le corps de la fenêtre
     */
    getMainMessage(): String {
        if (this.data.warning === 'DELETE_MEDIA') {
            return "Vous vous apprêtez à supprimer ce media.<br><strong>Tous les éléments qu'il contient seront définitivement supprimés.</strong>";
        }
    }
    /**
     * Détermine le message à afficher dans le bouton
     * @returns {String} Le message à afficher dans le bouton
     */
    getFooter(): String {
        if (this.data.warning === 'DELETE_MEDIA') {
            return 'Supprimer définitivement le media';
        }
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Supprime le media actuel
     */
    action() {
        if (this.data.warning === 'DELETE_MEDIA') {
            this.deleteMedia$ = this.mediaService
                .deleteMedia(this.data.warningId)
                .subscribe((data: any) => {
                    this.mediaService.refreshMedias();
                    this.mediaService.refreshMedia();
                    this.closeDialog();
                });
        }
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
