import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-save-media-confirmation',
    templateUrl: './save-media-confirmation.component.html',
    styleUrls: ['./save-media-confirmation.component.scss']
})
export class SaveMediaConfirmationComponent {
    cb_checked: boolean;
    constructor(
        private dialogRef: MatDialogRef<SaveMediaConfirmationComponent>,
        private cookieService: CookieService
    ) {}

    isCookie() {
        return this.cookieService.check('display save confirmation');
    }

    toggleCookie() {
        if (this.isCookie()) {
            this.cookieService.delete('display save confirmation', '/');
        } else {
            const expires = new Date();
            expires.setFullYear(expires.getFullYear() + 1);
            this.cookieService.set('display save confirmation', 'true', {
                expires: expires,
                path: '/',
                sameSite: 'None',
                secure: true
            });
        }
    }

    /**
     * Close the dialog without any return value.
     */
    closeDialog(data) {
        this.dialogRef.close(data);
    }
}
