<!-- <div class="save-confirmation">
    <div>
        <div class="row">
            <div class="col-2">
                <span class="icon-warning"></span>
            </div>
            <div class="title-content" class="col-8">
                <span style="vertical-align: sub">ENREGISTREMENT DE VOS MÉDIAS</span>
            </div>
            <div class="close-content" class="col-2">
                <span class="icon-close dialog-close" (click)="closeDialog()"></span>
            </div>
        </div>
    </div>
    <mat-dialog-content>
        <p>Attention, assurez-vous d'avoir enregistré votre travail avant de poursuivre.</p>
        <p>
            <button
                class="custom-checkbox"
                (click)="toggleCookie()"
                [class.checked]="isCookie()"
            ></button>
            Ne plus me demander
        </p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="confirm" mat-button [mat-dialog-close]="true">
            MON TRAVAIL EST ENREGISTRÉ JE POURSUIS !
        </button>
        <button class="cancel" mat-button mat-dialog-close>ANNULER</button>
    </mat-dialog-actions>
</div> -->

<div class="header">
    <span class="icon-Warning"></span>
    <span class="label">Enregistrement de vos médias</span>
    <span class="icon-close" (click)="closeDialog(false)"></span>
</div>
<mat-dialog-content class="content">
    <p>Attention, assurez-vous d'avoir enregistré votre travail avant de poursuivre.</p>
    <p>
        <button
            class="custom-checkbox"
            (click)="toggleCookie()"
            [class.checked]="isCookie()"
        ></button>
        Ne plus me demander
    </p>
</mat-dialog-content>
<div class="footer">
    <div class="confirm-button" (click)="closeDialog(true)">
        Mon travail est enregistré, je poursuis !
    </div>
    <div class="cancel-button" (click)="closeDialog(false)">Annuler</div>
</div>
